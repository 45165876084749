<template>
  <div class="number-boxs mt-4 position-relative numbers-box">
    <h6 class="mb-2">{{ $t('Reset Password') }}</h6>
    <div class="form-group position-relative rst-pass-cls">
      <i class="fas fa-lock"></i>
      <input class="form-control mb-0 h50" :type="show ? 'text' : 'password'" v-model="$v.newPassword.$model"
        :placeholder="$t('New Password')" aria-label="Password" @keyup.enter="LogIn"
        :class="{ 'is-invalid': $v.newPassword.$error }" minlength="6" maxlength="10" />
      <div class="eyeButton" @click="eyepassword('show')"><i v-if="show" class="fas fa-eye-slash"></i><i v-else
          class="fas fa-eye"></i></div>
      <div v-if="$v.newPassword.$error" class="invalid-feedback">
        <span v-if="!$v.newPassword.required">{{ $t('Please enter New password') }}</span>
      </div>
    </div>
    <div class="form-group position-relative rst-pass-cls mt-3">
      <i class="fas fa-lock"></i>
      <input class="form-control mb-0 h50" :type="cshow ? 'text' : 'password'" v-model="$v.repeatPassword.$model"
        :placeholder="$t('Confirm Password')" aria-label="Password" @keyup.enter="LogIn"
        :class="{ 'is-invalid': $v.repeatPassword.$error }" minlength="6" maxlength="10" />
      <div class="eyeButton" @click="eyepassword('cshow')"><i v-if="cshow" class="fas fa-eye-slash"></i><i v-else
          class="fas fa-eye"></i></div>
      <div v-if="$v.repeatPassword.$error" class="invalid-feedback">
        <span v-if="!$v.repeatPassword.required">{{ $t('Please enter Repeat Password') }}</span>
        <span v-else-if="!$v.repeatPassword.sameAsPassword">
          {{ $t('Passwords must match') }}
        </span>
      </div>
    </div>
    <div class="full-btn mt-4">
      <button @click="updatePassword">{{ $t('Submit') }}</button>
    </div>
  </div>
</template>
<script>
import Login from "../apis/login";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  props: ['commoninput'],
  data() {
    return {
      show: false,
      newPassword: "",
      repeatPassword: "",
      cshow: false,
      show: false,
    }
  },
  validations: {
    newPassword: {
      required,
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs("newPassword"),
    }
  },
  methods: {
    updatePassword() {
      this.$v.$touch();
      if (this.$v.newPassword.$invalid && this.$v.newPassword.$invalid) {
        return;
      } else {
        Login.customerChangePassword({
          mobile_number: this.commoninput,
          password: this.newPassword,
          vendor_id: this.$auth.getVendorId(),
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.data.code == 200) {
            this.$v.$reset();
            this.$toasted.success(data.data.msg, { duration: 2000 });
            this.$router.push({ name: 'sign-in' });
          } else {
            this.$toasted.error(data.data.msg, { duration: 2000 });
          }
        });
      }
    },

    eyepassword(value) {
      if (value == 'show') {
        if (this.show) {
          this.show = false;
        } else {
          this.show = true;
        }
      } else {
        if (this.cshow) {
          this.cshow = false;
        } else {
          this.cshow = true;
        }
      }
    },

  }
}

</script>